div.container {
    padding: 16px 12px 0;
}

div.container>div {
    background: #213863;
    border-radius: 4px;
    color: white;
    padding: 12px;
}

.topMessage {
    font-size: small;
    padding: 2px 0 8px;
}

.itemContainer {
    padding: 8px 0;
}

.itemContainer:last-child {
    padding: 8px 0 2px;
}

.itemLabel {
    font-size: small;
    font-weight: bold;
}

.itemContent {
    align-items: center;
    display: inline-flex;
    flex-wrap: wrap;
    font-size: small;
}

.itemOldValue {
    display: inline-block;
    text-decoration: line-through;
}

.itemNewValue {
    display: inline-block;
}