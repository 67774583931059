.completeIcon {
    color: #8bc34a;
    font-size: 2rem !important;
}

.completeText {
    font-size: 0.9rem;
    padding-left: 0.5rem;
    padding-top: 2px;
}

.processingText {
    font-size: 0.9rem;
    padding-left: 0.75rem;
    padding-top: 2px;
}

.errorPanel {
    color: #f44336;
    font-size: 0.8rem;
    margin: 0;
    padding-left: 2rem;
}
