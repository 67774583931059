div[data-is-compact="false"] .container,
div[data-is-compact="false"] .marginTopForOutlook
{
    background-color: #fff;
    border: 1px solid rgb(0 0 0 / 20%);
    border-radius: 0.25rem;
    margin-top: 0.5rem;
}

div[data-is-compact="false"] .bodyContainer {
    padding: 0.75rem 0.75rem 0;
}

.header {
    border-bottom: 1px solid rgb(0 0 0 / 20%);
    display: flex;
    padding: 0.75rem;
}

.headerLabel {
    color: rgb(55 71 79);
    font-size: 0.875rem;
    margin: auto 0;
}

div[data-variant="brand"] .headerLabel {
    color: white !important;
}

.marginTopForOutlook {
    box-shadow: 0 -0.5rem 0 rgb(244 246 254);
    margin-top: -2.5rem !important;
}

.dataCompareWrapper {
    padding: 16px 12px 0;
}

.dataCompareContainer {
    background: #213863;
    border-radius: 4px;
    color: white;
    padding: 12px;
}
