:root {
  --default-font: roboto, helvetica, arial, sans-serif;
  --light-gray: #c9c9c9;
  --mid-blue: #0176d3;
  --near-black: rgb(0 0 0 / 20%);
  --success: #45c65a;
  --themed-primary: #1976d2;
  --warning: #ba0517;
  --white: #fff;
}

body {
  background-color: var(--white);
  font-family: var(--default-font);
  margin: 0;
}

.main {
  height: 100vh;
  overflow-y: auto;
  padding: 0 .5rem;
}

.mainPadding {
  height: 48px;
  width: 100%;
}

.mainPaddingOutlook {
  width: 100%;
}

a {
  text-decoration: none;
}

div.input-autocomplete .MuiAutocomplete-endAdornment>button {
  transform: none !important;
}

label.MuiFormLabel-root.MuiInputLabel-standard {
  color: rgb(0 0 0 / 60%);
  display: block;
  font-family: var(--default-font);
  font-size: .75rem;
  font-weight: 400;
  left: 0;
  letter-spacing: 0.00938em;
  line-height: 1.4375em;
  max-width: 133%;
  overflow: hidden;
  padding: 0;
  position: absolute;
  text-overflow: ellipsis;
  top: 0;
  transform: translate(0, -1.5px);
  transform-origin: left top;
  white-space: nowrap;
}

.MuiInputBase-sizeSmall {
  font-size: 0.8125rem !important;
}

.MuiAutocomplete-listbox {
  padding: .25rem 0 !important;
}

.MuiAutocomplete-listbox li {
  font-size: .8125rem !important;
  min-height: 2rem !important;
  padding: 0 .75rem !important;
}

.MuiMenu-paper {
  margin-left: -0.5rem;
}

.MuiMenu-paper>ul {
  padding: 0.25rem 0;
}

.MuiMenu-paper>ul>li {
  font-size: .8125rem;
  min-height: 2rem;
  padding: 0 .75rem;
}

.date-picker input {
  padding: 1px 0 5px !important
}

.date-picker .MuiIconButton-root {
  margin: 0 -3px 0 0 !important;
  padding: 4px !important;
}

.MuiSvgIcon-fontSizeMedium {
  height: .75em !important;
  width: .75em !important;
}

.MuiTextField-root input.MuiInputBase-input.MuiInput-input {
  font-size: .8125rem !important;
}

.input-rich-text .ck.ck-content.ck-editor__editable {
  max-height: 20rem !important;
  min-height: 10rem !important;
  overflow: auto !important;
}

.input-rich-text .ck-content .table table,
.input-rich-text .ck-content .table table td,
.input-rich-text .ck-content .table table th {
  border: none !important;
}

.input-rich-text .ck.ck-dropdown .ck-dropdown__arrow {
  z-index: unset;
}

.input-rich-text {

  /* margin: 0 -10px 0 0 !important; */
}

.rsw-editor {
  max-height: 24rem !important;
  overflow-y: scroll !important;
}

/*****
* SLDS
******/

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.border {
  border: 1px solid var(--near-black)
}

.border-top {
  border-top: 1px solid var(--near-black);
}

.border-right {
  border-right: 1px solid var(--near-black);
}

.border-bottom {
  border-bottom: 1px solid var(--near-black);
}

.border-left {
  border-left: 1px solid var(--near-black);
}

.border-radius {
  border-radius: 0.25rem;
}

.btn {
  align-items: center;
  appearance: none;
  background: none;
  background-clip: border-box;
  border-radius: .25rem;
  -webkit-box-align: center;
  cursor: pointer;
  display: inline-flex;
  -ms-flex-align: center;
  line-height: 1.875rem;
  padding: 0 1rem;
  position: relative;
  text-decoration: none;
  user-select: none;
  white-space: normal;
}

.btn_neutral {
  background-color: var(--white);
  border: 1px solid var(--light-gray);
  color: var(--mid-blue);
}

.btn_brand {
  background-color: var(--mid-blue);
  border: 1px solid var(--mid-blue);
  color: var(--white);
}

.btn_outline-brand {
  background-color: var(--white);
  border: 1px solid var(--mid-blue);
  color: var(--mid-blue);
}

.btn_destructive {
  background-color: var(--warning);
  border: 1px solid var(--warning);
  color: var(--white);
}

.btn_text-destructive {
  background-color: var(--white);
  border: 1px solid var(--light-gray);
  color: var(--warning);
}

.btn_success {
  background-color: var(--success);
  border: 1px solid var(--success);
  color: #181818;
}

.btn_mui {
  background-color: var(--white);
  border: 1px solid var(--mid-blue);
  color: var(--mid-blue);
  display: inline-flex;
  font-size: 0.875rem;
  justify-content: center;
  padding: 0;
  text-transform: uppercase;
  width: 100%;
}

.float {
  background-color: rgb(244 246 254);
  border-top: 1px solid var(--near-black);
  bottom: 0;
  left: 0;
  padding: 0 1.5rem 0 .5rem;
  position: absolute;
  right: 0;
  z-index: 1000;
}

.grid {
  display: flex;
}

.grid_gap {
  display: flex;
  gap: 6px;
}

.grid_end {
  display: flex;
  justify-content: flex-end;
}

.grid_wrap {
  display: flex;
  flex-wrap: wrap;
}

.grid_spread {
  display: flex;
  justify-content: space-between;
}

.grid_center {
  align-items: center;
  -webkit-box-align: center;
  -webkit-box-pack: center;
  display: flex;
  margin: auto;
  place-content: center center;
}

.grid_reverse {
  -webkit-box-direction: reverse;
  -webkit-box-orient: horizontal;
  display: flex;
  flex-direction: row-reverse;
}

.grid_vertical {
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  display: flex;
  flex-direction: column;
}

.grid_vertical_reverse {
  -webkit-box-direction: reverse;
  -webkit-box-orient: vertical;
  display: flex;
  flex-direction: column-reverse;
}

.input {
  border: 1px solid var(--light-gray);
  border-radius: .25rem;
  display: inline-block;
  line-height: 1.875rem;
  min-height: calc(1.875rem + 2px);
  padding: 0 1rem 0 .75rem;
  transition: border .1s linear, background-color .1s linear;
}

.input:focus {
  border-color: #1b96ff;
  box-shadow: 0 0 3px var(--mid-blue);
  outline: none;
}

.size_1-1,
.size_2-2,
.size_3-3,
.size_4-4,
.size_5-5 {
  width: 100%;
}

.size_1-2 {
  width: 50%;
}

.size_1-3 {
  width: 33.333%;
}

.size_2-3 {
  width: 66.666%;
}

.size_1-4 {
  width: 25%;
}

.size_2-4 {
  width: 50%;
}

.size_3-4 {
  width: 75%;
}

.size_1-5 {
  width: 20%;
}

.size_2-5 {
  width: 40%;
}

.size_3-5 {
  width: 60%;
}

.size_4-5 {
  width: 80%;
}

.p-around_xx-small {
  padding: 0.125rem;
}

.p-around_x-small {
  padding: 0.25rem;
}

.p-around_small {
  padding: 0.5rem;
}

.p-around_medium {
  padding: 0.75rem;
}

.p-around_large {
  padding: 1rem;
}

.p-around_x-large {
  padding: 1.5rem;
}

.p-horizontal_xx-small {
  padding-left: 0.125rem;
  padding-right: 0.125rem;
}

.p-horizontal_x-small {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.p-horizontal_small {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.p-horizontal_medium {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.p-horizontal_large {
  padding-left: 1rem;
  padding-right: 1rem;
}

  .p-horizontal_x-large {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .p-left_xx-small {
    padding-left: 0.125rem;
  }

  .p-left_x-small {
    padding-left: 0.25rem;
  }

  .p-left_small {
    padding-left: 0.5rem;
  }

  .p-left_medium {
    padding-left: 0.75rem;
  }

  .p-left_large {
    padding-left: 1rem;
  }

  .p-left_x-large {
    padding-left: 1.5rem;
  }

  .p-right_xx-small {
    padding-right: 0.125rem;
  }

  .p-right_x-small {
    padding-right: 0.25rem;
  }

  .p-right_small {
    padding-right: 0.5rem;
  }

  .p-right_medium {
    padding-right: 0.75rem;
  }

  .p-right_large {
    padding-right: 1rem;
  }

  .p-right_x-large {
    padding-right: 1.5rem;
  }

  .p-vertical_xx-small {
    padding-bottom: 0.125rem;
    padding-top: 0.125rem;
  }

  .p-vertical_x-small {
    padding-bottom: 0.25rem;
    padding-top: 0.25rem;
  }

  .p-vertical_small {
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
  }

  .p-vertical_medium {
    padding-bottom: 0.75rem;
    padding-top: 0.75rem;
  }

  .p-vertical_large {
    padding-bottom: 1rem;
    padding-top: 1rem;
  }

  .p-vertical_x-large {
    padding-bottom: 1.5rem;
    padding-top: 1.5rem;
  }

  .p-top_xx-small {
    padding-top: 0.125rem;
  }

  .p-top_x-small {
    padding-top: 0.25rem;
  }

  .p-top_small {
    padding-top: 0.5rem;
  }

  .p-top_medium {
    padding-top: 0.75rem;
  }

  .p-top_large {
    padding-top: 1rem;
  }

  .p-top_x-large {
    padding-top: 1.5rem;
  }

  .p-bottom_xx-small {
    padding-bottom: 0.125rem;
  }

  .p-bottom_x-small {
    padding-bottom: 0.25rem;
  }

  .p-bottom_small {
    padding-bottom: 0.5rem;
  }

  .p-bottom_medium {
    padding-bottom: 0.75rem;
  }

  .p-bottom_large {
    padding-bottom: 1rem;
  }

  .p-bottom_x-large {
    padding-bottom: 1.5rem;
  }

  .m-around_xx-small {
    margin: 0.125rem;
  }

  .m-around_x-small {
    margin: 0.25rem;
  }

  .m-around_small {
    margin: 0.5rem;
  }

  .m-around_medium {
    margin: 0.75rem;
  }

  .m-around_large {
    margin: 1rem;
  }

  .m-around_x-large {
    margin: 1.5rem;
  }

  .m-horizontal_xx-small {
    margin-left: 0.125rem;
    margin-right: 0.125rem;
  }

  .m-horizontal_x-small {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }

  .m-horizontal_small {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  .m-horizontal_medium {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }

  .m-horizontal_large {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .m-horizontal_x-large {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .m-left_xx-small {
    margin-left: 0.125rem;
  }

  .m-left_x-small {
    margin-left: 0.25rem;
  }

  .m-left_small {
    margin-left: 0.5rem;
  }

  .m-left_medium {
    margin-left: 0.75rem;
  }

  .m-left_large {
    margin-left: 1rem;
  }

  .m-left_x-large {
    margin-left: 1.5rem;
  }

  .m-right_xx-small {
    margin-right: 0.125rem;
  }

  .m-right_x-small {
    margin-right: 0.25rem;
  }

  .m-right_small {
    margin-right: 0.5rem;
  }

  .m-right_medium {
    margin-right: 0.75rem;
  }

  .m-right_large {
    margin-right: 1rem;
  }

  .m-right_x-large {
    margin-right: 1.5rem;
  }

  .m-vertical_xx-small {
    margin-bottom: 0.125rem;
    margin-top: 0.125rem;
  }

  .m-vertical_x-small {
    margin-bottom: 0.25rem;
    margin-top: 0.25rem;
  }

  .m-vertical_small {
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }

  .m-vertical_medium {
    margin-bottom: 0.75rem;
    margin-top: 0.75rem;
  }

  .m-vertical_large {
    margin-bottom: 1rem;
    margin-top: 1rem;
  }

  .m-vertical_x-large {
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
  }

  .m-top_xx-small {
    margin-top: 0.125rem;
  }

  .m-top_x-small {
    margin-top: 0.25rem;
  }

  .m-top_small {
    margin-top: 0.5rem;
  }

  .m-top_medium {
    margin-top: 0.75rem;
  }

  .m-top_large {
    margin-top: 1rem;
  }

  .m-top_x-large {
    margin-top: 1.5rem;
  }

  .m-bottom_xx-small {
    margin-bottom: 0.125rem;
  }

  .m-bottom_x-small {
    margin-bottom: 0.25rem;
  }

  .m-bottom_small {
    margin-bottom: 0.5rem;
  }

  .m-bottom_medium {
    margin-bottom: 0.75rem;
  }

  .m-bottom_large {
    margin-bottom: 1rem;
  }

  .m-bottom_x-large {
    margin-bottom: 1.5rem;
  }

  .MuiAutocomplete-noOptions,
  .MuiAutocomplete-loading {
    font-size: 0.875rem !important;
    padding-bottom: 0.375rem !important;
    padding-top: 0.375rem !important;
  }


  .rsw-ce {
    overflow: scroll!important;
  }

.sign-in-success {
  display: grid;
  place-items: center;

  h2 {
    word-break: break-all;
  }

  .hide {
    display: none !important;
  }

  .btn-box {
    display: grid;
    gap: 12px;
    min-height: 60px;
    place-items: center;
  }

  .btn {
    background-color: #007BC2;
    border: none;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    max-width: 125px;
    padding: 10px 20px;
    text-decoration: none;
  }

  .btn:hover {
    background-color: #0181cb;
    transition: ease-in-out 300ms;
  }
}

.empty-button {
  appearance: none;
  background: none;
  border: 0;
  padding: 0;
}

.hidden-btn {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal;
}

.hidden-btn:focus-visible {
  clip: initial;
  clip-path: initial;
  display: inline-block;
  height: initial;
  margin: 14px;
  overflow: initial;
  position: relative;
  width: initial;
  word-wrap: initial;
}

.href-clean,
.href-clean:visited {
  color: inherit;
}

.underline {
  text-decoration: underline;
}

.warning-header {
  font-size: .875rem;
  font-weight: 400;
}

.warning-content {
  font-size: .875rem;
  font-weight: 400;
}