/* login component styles */

.btnImg {
    max-width: 205px;
    width: 100%;
}

.btnWrapper {
    margin: 8px 0 0 !important;
    padding: 0 !important;
}

.loginWrapper {
    background-color: #fff;
    margin-bottom: 0.5rem;
}

.selectWrapper {
    flex-direction: row !important;
}

.selectItem {
    width: 100%;
}
