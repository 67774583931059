.attachments {
    padding-bottom: 0.5rem;
}

.selectAllButton {
    appearance: none;
    background: none;
    border: 0;
    cursor: pointer;
    font-family: roboto, helvetica, arial, sans-serif;
    font-size: .665rem;
    padding: 0 0.5rem;
}

.unselectAllButton {
    appearance: none;
    background: none;
    border: 0;
    border-right: 1px solid #ccced1;
    cursor: pointer;
    font-family: roboto, helvetica, arial, sans-serif;
    font-size: .665rem;
    padding: 0 0.5rem;
}

.checkboxContainer {
    position: absolute;
    right: 0;
    width: 26px;
}

.checkboxContainer>span {
    height: 18px;
    width: 18px;
}

.selectorsContainer {
    display: flex;
    position: absolute;
    right: 0;
}

.selectorsContainer[data-column-count="1"] {
    width: 26px;
}

.selectorsContainer[data-column-count="2"] {
    width: 50px;
}

.fileNameContainer {
    line-break: anywhere;

    a:focus-within {
        box-shadow: 0 0 3pt 2pt #1976d2;
        transition: 250ms ease-in-out;
    }
}

.fileNameContainer[data-column-count="1"] {
    width: calc(100% - 70px);
}

.fileNameContainer[data-column-count="2"] {
    width: calc(100% - 92px);
}

.warningMessage {
    color: #ed6c02;
    padding-top: 0.5rem;
}