.buttonContainer {
    appearance: none;
    background: none;
    border: 0;
    cursor: pointer;
    padding: 0;
}

.buttonContainer[data-disabled="true"] {
    cursor: default;
    opacity: 40%;
}

.buttonContainer>span {
    height: 18px;
    width: 18px;
}
