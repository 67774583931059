/* save and close action buttons styles */

.actionButtons {
  padding: 0.5rem 0;
}

.saveButton {
  width: 50% !important;
}

.cancelButton {
  background-color: white !important;
  width: 50% !important;
}

.copyPasteWrapper {
  position: absolute;
  right: 13%;
  top: 18px;
}